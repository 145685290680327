<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.id"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <!-- <account-settings-account :account-data="accountSettingData.account"></account-settings-account> -->
        <general-settings></general-settings>
      </v-tab-item>

      <v-tab-item>
        <permission-settings-groups></permission-settings-groups>
      </v-tab-item>

      <v-tab-item>
        <permissions-settings-roles></permissions-settings-roles>
      </v-tab-item>

      <v-tab-item>
        <integration-settings></integration-settings>
      </v-tab-item>

      <v-tab-item>
        <account-settings-billing></account-settings-billing>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import store from '@/store'
import {
  mdiTools,
  mdiLockOpenOutline,
  mdiApplicationSettings,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import PermissionSettingsGroups from './PermissionSettingsGroups.vue'
import PermissionsSettingsRoles from './PermissionsSettingsRoles.vue'
import IntegrationSettings from './IntegrationSettings.vue'
import GeneralSettings from './GeneralSettings.vue'

export default {
  components: {
    IntegrationSettings,
    GeneralSettings,
    PermissionSettingsGroups,
    PermissionsSettingsRoles,
  },
  data() {
    return {
      tab: this.$route.params.tab??0,
      tabs: [
        { id: 'general-settings', title: this.$t('General'), icon: mdiTools },
        { id: 'groups-settings', title: this.$t('Groups'), icon: mdiLockOpenOutline },
        { id: 'roles-settings', title: this.$t('Roles'), icon: mdiLockOpenOutline },
        { id: 'integration-settings', title: this.$t('Integrations'), icon: mdiApplicationSettings },
      ],
    }
  },
  mounted() {
    store.dispatch('app/fetchAppsettings')
  },
  setup() {
    const tab = ref('')

    // account settings data
    const accountSettingData = {
      account: {
        avatarImg: require('@/assets/images/avatars/1.png'),
        username: 'johnDoe',
        name: 'john Doe',
        email: 'johnDoe@example.com',
        role: 'Admin',
        status: 'Active',
        company: 'Google.inc',
      },
      information: {
        bio: 'The name’s John Deo. I am a tireless seeker of knowledge, occasional purveyor of wisdom and also, coincidentally, a graphic designer. Algolia helps businesses across industries quickly create relevant 😎, scaLabel 😀, and lightning 😍 fast search and discovery experiences.',
        birthday: 'February 22, 1995',
        phone: '954-006-0844',
        website: 'https://themeselection.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
      notification: {
        commentOnArticle: true,
        answerOnForm: true,
        followMe: false,
        newsAnnouncements: false,
        productUpdates: true,
        blogDigest: false,
      },
    }

    return {
      tab,
      accountSettingData,
      icons: {
        mdiTools,
        mdiLockOpenOutline,
        mdiApplicationSettings,
      },
    }
  },
}
</script>
