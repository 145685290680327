<template>
  <v-form
    v-if="getAppsettingsGlobal"
    ref="form"
    @submit.prevent="submit()"
  >
    <v-card>
      <v-card-title>{{ $t('Standby mode') }}</v-card-title>
      <v-card-text>
        <v-row class="pt-5">
          <v-col
            cols="12"
            md="6"
            class="d-flex align-center mt-4"
          >
            <label for="global_standby_mode">{{ $t('Activate standby mode') }}</label>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-checkbox
              id="global_standby_mode"
              v-model="settings.global_standby_mode"
              hide-details
              :label="`${checkboxLabel}`"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="settings.global_standby_mode"
          class="pt-5"
        >
          <v-col
            cols="12"
            md="6"
          >
            {{ $t('Defines a fixed time frame in which the page should be visible (settings here override a relative time specification). Visibility can be defined in pure time windows (e.g. 10:00 - 18:00), a date range (e.g. 01.01. - 15.01.) or by weekdays. Combinations of several entries further filter the visibility.') }}
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <timeframe
              @addTimeframe="addTimeframe"
            ></timeframe>
            <timeframe-list
              class="pt-5"
              :timeframes="settings.global_standby_mode_timeframe"
              @removeTimeframe="removeTimeframe"
            ></timeframe-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text
        v-if="settings.global_standby_mode"
        class="pt-5"
      >
        <v-card-title>{{ $t('Design during standby mode') }}</v-card-title>
        <v-card-text>
          <v-row class="pt-5">
            <v-col
              cols="12"
              md="6"
            >
              <label for="global_standby_mode_background_type">{{ $t('Standby mode background type') }}</label>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="settings.global_standby_mode_background_type"
                :items="backgroundTypes"
                item-text="text"
                item-value="value"
                :label="$t('Standby mode background type')"
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            v-if="settings.global_standby_mode_background_type == 'image'"
            class="pt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <div v-if="settings.global_standby_mode_background_image">
                <p>{{ $t('Current Backgroundimage') }}</p>
                <v-img
                  :src="settings.global_standby_mode_background_image+'?rnd='+Date.now()"
                  max-width="250"
                ></v-img>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-file-input
                ref="bgImage"
                v-model="global_standby_mode_background_file"
                accept="image/jpeg,image/png"
                :label="$t('Upload Backgroundimage')"
                hide-details
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row
            v-if="settings.global_standby_mode_background_type == 'color'"
            class="pt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-color-picker
                v-model="global_standby_mode_background_color"
                mode="hexa"
              >
              </v-color-picker>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card-text>
      <v-card-actions>
        <v-btn
          type="submit"
          color="success"
          :timeout="500"
          :loading="isSnackbarVisible"
          :disabled="isSnackbarVisible"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar
      v-model="isSnackbarVisible"
      :light="$vuetify.theme.dark"
      multi-line
      :color="snackbarColor"
    >
      <p
        v-for="msg in snackbarMessages"
        :key="msg"
      >
        {{ msg }}
      </p>
    </v-snackbar>
  </v-form>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import axios from '@axios'
import Timeframe from './GeneralSettingsGlobalStandbyTimeframe.vue'
import TimeframeList from './GeneralSettingsGlobalStandbyList.vue'

export default {
  components: {
    Timeframe,
    TimeframeList,
  },
  data() {
    return {
      rules: {
        required: [value => !!value || this.$t('Required')],
      },
      settings: {
        global_standby_mode: null,
        global_standby_mode_timeframe: [],
        global_standby_mode_background_type: null,
        global_standby_mode_background_image: null,
      },
      global_standby_mode_background_file: null,
      global_standby_mode_background_color: '',
      backgroundTypes: [
        {
          text: this.$t('image'),
          value: 'image',
        },
        {
          text: this.$t('color'),
          value: 'color',
        },
      ],
      snackbarMessages: [],
      snackbarColor: 'success',
      isSnackbarVisible: false,

    }
  },
  computed: {
    ...mapGetters('app', ['getAppsettingsGlobal', 'getAppsettingsValueByKey', 'getAppsettingsExists']),
    checkboxLabel() {
      if (this.settings.global_standby_mode) return this.$t('Active')

      return this.$t('Deactivated')
    },
  },
  watch: {
    getAppsettingsGlobal() {
      this.settings.global_standby_mode = Boolean(Number(this.getAppsettingsValueByKey('global_standby_mode')))
      this.settings.global_standby_mode_timeframe = this.getAppsettingsValueByKey('global_standby_mode_timeframe')
      if (this.settings.global_standby_mode_timeframe) {
        this.settings.global_standby_mode_timeframe = JSON.parse(this.settings.global_standby_mode_timeframe)
      }

      this.settings.global_standby_mode_background_type = this.getAppsettingsValueByKey('global_standby_mode_background_type')
      this.settings.global_standby_mode_background_image = this.getAppsettingsValueByKey('global_standby_mode_background_image')
      if (this.settings.global_standby_mode_background_type === 'color') {
        this.global_standby_mode_background_color = this.settings.global_standby_mode_background_image
      }
      if (this.settings.global_standby_mode_timeframe == null) {
        this.settings.global_standby_mode_timeframe = []
      }
    },
  },
  methods: {
    submit() {
      if (this.settings.global_standby_mode_background_type === 'color') {
        this.settings.global_standby_mode_background_image = this.global_standby_mode_background_color
        this.sendSettings()
      } else if (this.settings.global_standby_mode_background_type === 'image' && this.global_standby_mode_background_file) {
        const that = this
        this.uploadImage().then(imgUrl => {
          that.settings.global_standby_mode_background_image = imgUrl
          that.sendSettings()
        })
      } else {
        this.sendSettings()
      }
    },
    sendSettings() {
      this.snackbarMessages = []
      Object.keys(this.settings).forEach(key => {
        let myValue = ''
        if (key === 'global_standby_mode_timeframe') {
          myValue = JSON.stringify(this.settings[key])
        } else {
          myValue = this.settings[key]
        }

        if (this.getAppsettingsExists(key)) {
          store.dispatch('app/updateAppsetting', {
            appsetting: {
              key,
              value: myValue,
            },
          }).then(response => {
            if (response.data.success) {
              this.snackbarMessages.push(`${this.$t(key)} ${this.$t('successfully updated')}`)
              this.snackbarColor = 'success'
            } else {
              console.log(`Error: ${response.data.message}`)
              this.snackbarMessages.push(`${key} failed`)
              this.snackbarColor = 'error'
            }
          }, error => {
            // console.log(`Error: ${error}`)
            const errorMessage = error.response.data.message
            this.snackbarMessages.push(this.$t(errorMessage))
            this.snackbarColor = 'error'
          })
          this.isSnackbarVisible = true
        } else {
          store.dispatch('app/storeAppsetting', {
            appsetting: {
              key,
              value: this.settings[key],
            },
          }).then(response => {
            if (response.data.success) {
              this.snackbarMessages.push(`${this.$t(key)} ${this.$t('successfully saved')}`)
              this.snackbarColor = 'success'
            } else {
              console.log(`Error: ${response.data.message}`)
              this.snackbarMessages.push(`${key} failed`)
              this.snackbarColor = 'error'
            }
          }, error => {
            console.log(`Error: ${error}`)
            const errorMessage = error.response.data.message
            this.snackbarMessages.push(this.$t(errorMessage))
            this.snackbarColor = 'error'
          })
          this.isSnackbarVisible = true
        }
      })
    },
    uploadImage() {
      const formData = new FormData()
      formData.append('file', this.global_standby_mode_background_file)

      return new Promise((resolve, reject) => {
        axios.post('/appsettings/uploadimage', formData).then(
          response => {
            if (response.data.success) {
              resolve(response.data.data)
            } else {
              console.log(`Error: ${response.data.message}`)
              reject(response.data.message)
            }
          },
          error => {
            console.log(`Error: ${error}`)
            reject(error)
          },
        )
      })
    },
    addTimeframe(data) {
      this.settings.global_standby_mode_timeframe.push(data)
    },
    removeTimeframe(id) {
      this.settings.global_standby_mode_timeframe = this.settings.global_standby_mode_timeframe.filter(frame => frame.id !== id)
    },
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
