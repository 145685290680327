<template>
  <div>
    <v-card
      flat
      class="mt-5"
    >
      <v-card-title>Formwerk</v-card-title>
      <v-card-text>
        <v-row>
          <!-- <v-form v-model="formValid" ref="formKey"> -->
          <v-form
            ref="FormFormwerk"
            style="width: 100%"
            @submit.prevent="submit()"
          >
            <v-col
              cols="12"
              md="9"
            >
              <v-text-field
                v-model="formwerkSettings.formwerk_api_key"
                :rules="rules.required"
                label="API-Key"
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <v-text-field
                v-model="formwerkSettings.formwerk_api_url"
                :rules="rules.required"
                label="API-Url"
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <v-text-field
                v-model="formwerkSettings.formwerk_token_secret_key"
                :rules="rules.required"
                label="API-Token-Secret-Key"
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-btn
                type="submit"
                color="success"
              >
                {{ $t('Save') }}
              </v-btn>
            </v-col>
          </v-form>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
    >
      <p
        v-for="msg in messages"
        :key="msg"
      >
        {{ msg }}
      </p>
    </v-snackbar>
  </div>
</template>
<script>
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      rules: {
        required: [value => !!value || this.$t('Required')],
      },
      message: null,
      formwerkSettings: {
        formwerk_api_key: '',
        formwerk_api_url: '',
        formwerk_token_secret_key: '',
      },
      messages: [],
      snackbar: false,
      snackbarColor: null,
    }
  },
  computed:
    {
      ...mapGetters('app', ['getAppsettingsFormwerk']),
    },
  watch: {
    getAppsettingsFormwerk() {
      this.formwerkSettings.formwerk_api_key = this.getValueByKey('formwerk_api_key')
      this.formwerkSettings.formwerk_api_url = this.getValueByKey('formwerk_api_url')
      this.formwerkSettings.formwerk_token_secret_key = this.getValueByKey('formwerk_token_secret_key')
    },
  },
  mounted() {
    store.dispatch('app/fetchAppsettings')
  },
  methods: {
    submit() {
      this.messages = []
      if (this.$refs.FormFormwerk.validate()) {
        Object.keys(this.formwerkSettings).forEach(key => {
          if (this.getValueByKey(key)) {
            // update
            store.dispatch('app/updateAppsetting', {
              appsetting: {
                key,
                value: this.formwerkSettings[key],
              },
            }).then(response => {
              if (response.data.success) {
                this.messages.push(`${this.$t(key)} ${this.$t('successfully updated')}`)
                this.snackbar = true
                this.snackbarColor = 'success'
              } else {
                console.log(`Error: ${response.data.message}`)
              }
            }, error => {
              console.log(`Error: ${error}`)
            })
          } else {
            // store
            store.dispatch('app/storeAppsetting', {
              appsetting: {
                key,
                value: this.formwerkSettings[key],
              },
            }).then(response => {
              if (response.data.success) {
                this.messages.push(`${this.$t(key)} ${this.$t('successfully updated')}`)
                this.snackbar = true
              } else {
                console.log(`Error: ${response.data.message}`)
              }
            }, error => {
              console.log(`Error: ${error}`)
            })
          }
        })
      }
    },
    getValueByKey(key) {
      const item = this.getAppsettingsFormwerk.find(setting => setting.key === key)
      if (item) {
        return item.value
      }

      return null
    },
  },
}
</script>
