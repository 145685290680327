<template>
  <div>
    <v-card
      flat
      class="mt-5"
    >
      <v-card-title>
        <v-row justify="space-between">
          <v-col>
            {{ $t('Groups') }}
          </v-col>
          <v-col class="text-right">
            <v-btn
              color="primary"
              @click="openAddDialog"
              @click.stop="isDialogVisible = true"
            >
              <v-icon>
                {{ icons.mdiPlus }}
                <span>{{ $t('Add New User') }}</span>
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ $t('Name') }}
                </th>
                <th>
                  {{ $t('Number of participants') }}
                </th>
                <th>
                  {{ $t('Created') }}
                </th>
                <th>
                  {{ $t('Actions') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="group in groups"
                :key="group.id"
              >
                <td>{{ group.name }}</td>
                <td v-if="group.users_count == 0">
                  {{ group.users_count }}
                </td>
                <td v-else>
                  <a @click="openUserListDialog(group)">
                    {{ group.users_count }}
                  </a>
                </td>

                <td>{{ group.created_at_24h }}</td>
                <td>
                  <v-btn
                    icon
                    fab
                    small
                    depressed
                    :id="group.id"
                    color="warning"
                    @click="openEditDialog(group)"
                    @click.stop="isDialogVisible = true"
                  >
                    <v-icon>
                      {{ icons.mdiGreasePencil }}
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    fab
                    small
                    depressed
                    color="error"
                    @click="openDeleteGroupModal(group)"
                  >
                    <v-icon>
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-dialog
          v-model="deleteGroupModal"
          width="30%"
          height="auto"
          name="delete_Accounts"
        >
          <v-card>
            <v-card-title class="light">
              {{ $t('Are you sure you want to delete this group ?') }}
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn
                outlined
                color="primary"
                @click="closeModal"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <v-btn
                color="error"
                @click="deleteGroup()"
              >
                {{ $t('Delete') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
    <EditAddGroup
      :visible="isDialogVisible"
      :group="selectedGroup"
    />
    <UserList
      :visible="isUserListVisible"
      :group="selectedGroup"
    />
  </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiDeleteOutline, mdiGreasePencil, mdiPlus, mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { mapState } from 'vuex'
import EditAddGroup from './EditAddGroup.vue'
import UserList from './UserList.vue'

export default {
  components: {
    EditAddGroup, UserList,
  },
  data() {
    return {
      isDialogVisible: false,
      isUserListVisible: false,
      selectedGroup: null,
    }
  },
  computed: {
    ...mapState('app', ['groups']),
  },
  mounted() {
    store.dispatch('app/fetchGroups')
     console.log(this.$route.params.groupSelected);
     groupSelected = document.getElementById(`${this.$route.params.groupSelected}`).click();

    // this.openEditDialog(this.$route.params.groupSelected)
  },
  methods: {
    openAddDialog() {
      this.selectedGroup = null
      this.isDialogVisible = true
    },
    openEditDialog(group) {
      this.selectedGroup = group
      this.isDialogVisible = true
    },
    openUserListDialog(group) {
      this.selectedGroup = group
      this.isUserListVisible = true
    },
    openDeleteGroupModal(group) {
      this.deleteGroupModal = true
      this.selectedGroupId = group.id
    },
    closeModal() {
      this.deleteGroupModal = false
    },
    deleteGroup() {
      store.dispatch('app/deleteGroup', {
        group: this.selectedGroupId,
      })
      this.closeModal()
    },
  },
  setup() {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('12345678')
    const newPassword = ref('87654321')
    const cPassword = ref('87654321')

    return {
      deleteGroupModal: false,
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiPlus,
        mdiGreasePencil,
        mdiDeleteOutline,
      },
    }
  },
}
</script>
