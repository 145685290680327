<template>
  <div v-if="timeframes.length > 0">
    <v-card
      v-for="frame in timeframes"
      :key="frame.id"
      class="pb-5"
    >
      <v-card-title>
        Zeitrahmen
        <v-btn
          icon
          color="danger"
          @click="remove(frame.id)"
        >
          <v-icon>
            {{ icons.mdiDelete }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <ul>
          <li v-if="frame.startDate">
            {{ $t('Start Date') }}: {{ frame.startDate }}
          </li>
          <li v-if="frame.endDate">
            {{ $t('End Date') }}: {{ frame.endDate }}
          </li>
          <li v-if="frame.startTime">
            {{ $t('Start Time') }}: {{ frame.startTime }}
          </li>
          <li v-if="frame.endTime">
            {{ $t('End Time') }}: {{ frame.endTime }}
          </li>
          <li v-if="frame.weekday && frame.weekday.length">
            {{ $t('Weekdays') }}: {{ frame.weekday.map(w => weekdays[w]).filter(w => w).join(', ') }}
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiDelete } from '@mdi/js'

export default {
  props: {
    timeframes: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      weekdays: {
        monday: this.$t('Monday'),
        tuesday: this.$t('Tuesday'),
        wednesday: this.$t('Wednesday'),
        thursday: this.$t('Thursday'),
        friday: this.$t('Friday'),
        saturday: this.$t('Saturday'),
        sunday: this.$t('Sunday'),
      },
    }
  },
  methods: {
    remove(id) {
      this.$emit('removeTimeframe', id)
    },
  },
  setup() {
    return {
      icons: {
        mdiDelete,
      },
    }
  },
}
</script>
