<template>
  <v-dialog
    v-model="visible"
    persistent
    max-width="700px"
  >
    <v-card>
      <v-card-title>{{ $t('Users in Group') }}</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ $t('First Name') }}
                </th>
                <th>
                  {{ $t('Last Name') }}
                </th>
                <th>
                  {{ $t('Email') }}
                </th>
                <th>
                  {{ $t('Actions') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <router-link
                v-for="user in group.users"
                :key="user.id"
                :to="{ name: 'apps-user-view', params: { id: user.id } }"
                tag="tr"
              >
                <td>{{ user.first_name }}</td>
                <td>{{ user.last_name }}</td>

                <td>{{ user.email }}</td>
                <td>
                  <v-btn
                    icon
                    fab
                    x-small
                    color="error"
                    @click="removeFromGroup(user)"
                  >
                    <v-icon dark>
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                  </v-btn>
                </td>
              </router-link>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          @click="$parent.isUserListVisible = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable eqeqeq */
import { mdiDeleteOutline } from '@mdi/js'
import store from '@/store'

export default {
  props: {
    group: Object,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    removeFromGroup(removeUser) {
      const currentUsers = [...this.group.user_ids]
      const newUsers = currentUsers.filter(user => user != removeUser.id)
      store.dispatch('app/assignUsersToGroup', {
        group: this.group,
        users: newUsers,
      }).then(response => {
        if (response.data.success) {
          this.$parent.isUserListVisible = false
        } else {
          console.log(`Error: ${response.data.message}`)
        }
      }, error => {
        console.log(`Error: ${error}`)
      })
    },
  },
  setup() {
    return {
      icons: {
        mdiDeleteOutline,
      },
    }
  },
}

</script>
