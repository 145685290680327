<template>
  <div>
    <general-settings-global-standby></general-settings-global-standby>
  </div>
</template>

<script>
import GeneralSettingsGlobalStandby from './GeneralSettingsGlobalStandby.vue'

export default {
  components: {
    GeneralSettingsGlobalStandby,
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
