<template>
  <div>
    <integration-settings-formwerk></integration-settings-formwerk>
  </div>
</template>
<script>
import IntegrationSettingsFormwerk from './IntegrationSettingsFormwerk.vue'

export default {
  components: {
    IntegrationSettingsFormwerk,
  },
}
</script>
