<template>
  <v-dialog
    v-model="visible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ headline }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="myForm"
            v-model="formValid"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  :rules="rules.required"

                  :label="$t('Name')"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="slug"
                  :rules="rules.required"

                  :label="$t('Slug')"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="description"
                  :rules="rules.required"

                  :label="$t('Description')"
                  dense
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="selectedUsers"
                  :items="users"
                  :label="$t('Users')"
                  item-text="name"
                  item-value="id"
                  dense
                  multiple
                  clearable
                >
                  <template
                    slot="selection"
                    slot-scope="data"
                  >
                    {{ data.item.full_name }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data"
                  >
                    <div>{{ data.item.full_name }}</div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          @click="$parent.isDialogVisible = false"
        >
          {{ $t('Close') }}
        </v-btn>
        <v-btn
          v-if="group"
          color="success"
          @click="update(group)"
        >
          {{ $t('Save') }}
        </v-btn>
        <v-btn
          v-else
          color="success"
          @click="add()"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'

export default {
  props: {
    group: Object,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formValid: false,
      rules: {
        required: [value => !!value || this.$t('Required')],
      },
      name: '',
      slug: '',
      description: '',
      selectedUsers: [],
    }
  },
  watch: {
    visible() {
      if (this.group) {
        this.name = this.group.name
        this.slug = this.group.slug
        this.description = this.group.description
        this.selectedUsers = this.group.user_ids
      } else {
        this.name = ''
        this.slug = ''
        this.description = ''
        this.selectedUsers = []
      }
    },
  },
  mounted() {
    store.dispatch('app/fetchUsers')
  },
  methods: {
    update() {
      this.$refs.myForm.validate()
      if (this.formValid) {
        store.dispatch('app/updateGroup', {
          group: {
            id: this.group.id,
            name: this.name,
            slug: this.slug,
            description: this.description,
            users: this.selectedUsers,
          },
        }).then(response => {
          if (response.data.success) {
            this.$parent.isDialogVisible = false
          } else {
            console.log(`Error: ${response.data.message}`)
          }
        }, error => {
          console.log(`Error: ${error}`)
        })
      }
    },
    add() {
      this.$refs.myForm.validate()
      if (this.formValid) {
        store.dispatch('app/storeGroup', {
          group: {
            name: this.name,
            slug: this.slug,
            description: this.description,
            users: this.selectedUsers,
          },
        }).then(response => {
          if (response.data.success) {
            this.$parent.isDialogVisible = false
          } else {
            console.log(`Error: ${response.data.message}`)
          }
        }, error => {
          console.log(`Error: ${error}`)
        })
      }
    },
  },
  computed:
    {
      ...mapState('app', ['users']),
      headline() {
        if (this.group) {
          return this.$t('Edit Group')
        }

        return this.$t('Add Group')
      },
    },

  setup() {
  },
}
</script>
