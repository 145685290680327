<template>
  <form
    ref="myForm"
    @submit.prevent="submit"
  >
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <label for="startdate">{{ $t('Start Date') }}</label>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <!--
                <v-date-picker
                    id="startdate"
                    v-model="startDate"
                    color="primary"
                ></v-date-picker>
            -->
        <input
          id="startdate"
          v-model="timeframe.startDate"
          :max="timeframe.endDate"
          type="date"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <label for="enddate">{{ $t('End Date') }}</label>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <!--
                <v-date-picker
                    id="enddate"
                    v-model="endDate"
                    color="primary"
                ></v-date-picker>
            -->
        <input
          id="enddate"
          v-model="timeframe.endDate"
          :min="timeframe.startDate"
          type="date"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <label for="starttime">{{ $t('Start Time') }}</label>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <!--
                 <v-time-picker
                    id="starttime"
                    :max="endTime"
                    v-model="startTime"
                    format="24hr"
                    color="primary"
                ></v-time-picker>
            -->
        <input
          id="starttime"
          v-model="timeframe.startTime"
          type="time"
          :max="!timeframe.startDate || timeframe.startDate === timeframe.endDate ? timeframe.endTime: undefined"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <label for="endtime">{{ $t('End Time') }}</label>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <!--
                <v-time-picker
                    id="endtime"
                    :min="startTime"
                    v-model="endTime"
                    format="24hr"
                    color="primary"
                ></v-time-picker>
            -->
        <input
          v-model="timeframe.endTime"
          type="time"
          :min="!timeframe.startDate || timeframe.startDate === timeframe.endDate ? timeframe.startTime: undefined"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <label for="weekday">{{ $t('Weekdays') }}</label>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <v-combobox
          id="weekday"
          v-model="timeframe.weekday"
          :items="weekdays"
          item-text="text"
          item-value="value"
          class="evo-combobox"
          chips
          clearable
          :label="$t('Weekdays')"
          multiple
          solo
        ></v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          :disabled="Object.keys(timeframe || {}).filter(k => timeframe && timeframe[k]).length > 0 ? false : true"
          color="primary"
          type="submit"
        >
          {{ $t('Add Timeframe') }}
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>
<script>
export default {
  props: {
    timeframe: {
      type: Object,
      default() {
        return {
          startDate: null,
          endDate: null,
          startTime: null,
          endTime: null,
          weekday: null,
        }
      },
    },
  },
  data() {
    return {

      weekdays: [
        {
          text: this.$t('Monday'),
          value: 'monday',
        },
        {
          text: this.$t('Tuesday'),
          value: 'tuesday',
        },
        {
          text: this.$t('Wednesday'),
          value: 'wednesday',
        },
        {
          text: this.$t('Thursday'),
          value: 'thursday',
        },
        {
          text: this.$t('Friday'),
          value: 'friday',
        },
        {
          text: this.$t('Saturday'),
          value: 'saturday',
        },
        {
          text: this.$t('Sunday'),
          value: 'sunday',
        },
      ],
    }
  },
  methods: {
    submit() {
      if (Object.keys(this.timeframe || {}).filter(k => this.timeframe && this.timeframe[k]).length) {
        this.timeframe.id = Math.random()
        if (Array.isArray(this.timeframe.weekday)) {
          this.timeframe.weekday = this.timeframe.weekday.map(item => item.value)
        }
        this.$emit('addTimeframe', this.timeframe)
        this.timeframe = {
          startDate: null,
          endDate: null,
          startTime: null,
          endTime: null,
          weekday: null,
        }
      }
    },
  },
}
</script>
