<template>
  <div>
    <v-card
      flat
      class="mt-5"
    >
      <v-card-title>
        <v-row justify="space-between">
          <v-col>
            {{ $t('Roles and Permissions') }}
          </v-col>
          <v-col class="text-right">
            <v-btn
              dark
              small
              color="success"
              @click="openAddDialog"
              @click.stop="isDialogVisible = true"
            >
              <v-icon dark>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ $t('Name') }}
                </th>
                <th>
                  {{ $t('Slug') }}
                </th>
                <th>
                  {{ $t('Created') }}
                </th>
                <th>
                  {{ $t('Actions') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="role in roles"
                :key="role.id"
              >
                <td>{{ role.name }}</td>
                <td>
                  {{ role.slug }}
                </td>
                <td>{{ role.created_at_24h }}</td>
                <td>
                  <v-btn
                    icon
                    fab
                    small
                    depressed
                    color="warning"
                    @click="openEditDialog(role)"
                    @click.stop="isDialogVisible = true"
                  >
                    <v-icon dark>
                      {{ icons.mdiGreasePencil }}
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    fab
                    small
                    depressed
                    color="error"
                    @click="openDeleteRoleModal(role)"
                  >
                    <v-icon dark>
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-dialog
          v-model="deleteRoleModal"
          width="30%"
          height="auto"
          name="delete_Accounts"
        >
          <v-card>
            <v-card-title class="light">
              {{ $t('Are you sure you want to delete this group ?') }}
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn
                outlined
                color="primary"
                @click="closeModal"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <v-btn
                color="error"
                @click="deleteRole()"
              >
                {{ $t('Delete') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
    <EditAddRole
      :visible="isDialogVisible"
      :role="selectedRole"
    />
    <v-snackbar
      v-model="snackbarShow"
      :color="snackbarColor"
      top
    >
      {{ $t(snackbarMessage) }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbarShow = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiDeleteOutline, mdiGreasePencil, mdiPlus, mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { mapState } from 'vuex'
import EditAddRole from './EditAddRole.vue'

export default {
  components: {
    EditAddRole,
  },
  data() {
    return {
      isDialogVisible: false,
      isUserListVisible: false,
      selectedGroup: null,
      snackbarShow: false,
      snackbarMessage: null,
      snackbarColor: null,
      selectedRole: null,
      success: 'The role was deleted successfully',
    }
  },
  computed: {
    ...mapState('app', ['roles']),
  },
  mounted() {
    store.dispatch('app/fetchRoles')
  },
  methods: {
    openAddDialog() {
      this.selectedRole = null
      this.isDialogVisible = true
    },
    openEditDialog(role) {
      this.selectedRole = role
      this.isDialogVisible = true
    },
    openDeleteRoleModal(role) {
      this.deleteRoleModal = true
      this.selectedRoleId = role.id
    },
    closeModal() {
      this.deleteRoleModal = false
    },
    errorMessage(error) {
      this.snackbarShow = true
      this.snackbarColor = 'error'
      this.snackbarMessage = error
    },
    successMessage() {
      this.snackbarColor = 'success'
      this.snackbarMessage = this.success
      this.snackbarShow = true
    },
    deleteRole() {
      store.dispatch('app/deleteRole', {
        role: this.selectedRoleId,
      }).then(response => {
        store.dispatch('app/fetchRoles')
        this.successMessage(response.message)
      }).catch(error => {
        const roleError = error.response.data.message
        console.log('una dia', roleError)
        this.snackbarColor = 'error'
        this.snackbarMessage = this.$t(roleError)
        this.snackbarShow = true
      })
      this.closeModal()
    },
  },
  setup() {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('12345678')
    const newPassword = ref('87654321')
    const cPassword = ref('87654321')

    return {
      deleteRoleModal: false,
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiPlus,
        mdiGreasePencil,
        mdiDeleteOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
